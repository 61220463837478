.rap {
flex-wrap: wrap;
}

.box-shadow {
	box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02) 
}

.spaced {
	padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}

.navbar-item img {
	max-height: 3.25rem;
}


.isGray {
	margin-top: 35px;
	margin-bottom: 35px;
	background-color: #dadcd9;
}